/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: 'Quicksand, Arial';
}

.main-content {
  display: flex;
  width: calc(100% - 24px);
  position: fixed;
  justify-content: center;
  /* 56 (topbar) + 24 + 24 */
  height: calc(100% - 104px);
  margin: 24px 0 0 24px;
  padding-right: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .main-content {
    margin: 8px 0 0 8px;
    padding-right: 8px;
    width: calc(100% - 8px);
  }
}

.main-content > * {
  flex-grow: 1;
  width: calc(100% - 24px);
}

.top-alert + .main-content {
  /* 56 (topbar) + 48 alert + 24 */
  height: calc(100% - 138px);
}

.documents-small {
  font-size: 12px;
  text-align: center;
  margin-bottom: 4px;
}

.documents-small a {
  color: #444;
}
